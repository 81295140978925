import { useState } from "react";
import { supabase } from "./supabaseClient";
import "./Invite.css";

const Invite = ({ todo2 }) => {
  // function handleClick() {}
  return (
    <div
      style={{
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
        background: "#16181F",
        margin: "1rem 0rem 2rem 0rem",
        padding: "1.5rem 0rem 2rem 0rem",
        width: "50rem",
      }}
    >

      <p
        style={{
          color: "white",
          margin: "0rem",
          fontFamily: "Montserrat",
          fontWeight: "800",
          fontSize: "24px",
          letterSpacing: "1px",
        }}
      >
        {" "}
        {todo2.tournamentname}{" "}
      </p>
      <div>
        <p
          style={{
            color: "white",
            margin: "0rem",
            padding: "1rem 0rem 0rem 0rem",
            fontFamily: "Montserrat",
            fontWeight: "900",
            fontSize: "14px",
            letterSpacing: "1px",
          }}
        >
          INVITE CREATOR
        </p>
        <p
          style={{
            color: "white",
            margin: "0rem",
            padding: "0rem 0rem 0rem 0rem",
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "14px",
            letterSpacing: "1px",
          }}
        >
          {todo2.currentwallet}
        </p>
      </div>
      <div>
        <p
          style={{
            margin: "0rem",
            padding: "1rem 0rem 0rem 0rem",
            color: "white",
            fontFamily: "Montserrat",
            fontWeight: "900",
            fontSize: "14px",
            letterSpacing: "1px",
          }}
        >
          GUESTLIST
        </p>
        {todo2.invitednfts.length ? (
            todo2.invitednfts.map((invited, i) => <p style={{
            margin: "0rem",
            padding: "0rem 0rem 0rem 0rem",
            color: "white",
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "14px",
            letterSpacing: "1px",
          }} key={i}>
          {JSON.parse(invited).name}
        </p> )
      ) : (
        <span className={"h-full flex justify-center items-center"}>
          You have not created any invites yet!
        </span>
      )}
      </div>
      <div>
      <p
        style={{
          margin: "0rem",
          padding: "1rem 0rem 0rem 0rem",
          color: "white",
          fontFamily: "Montserrat",
          fontWeight: "900",
          fontSize: "14px",
          letterSpacing: "1px",
        }}
      >
        {" "}
        TOURNAMENT LINK
      </p>
      <a
        href={todo2.tournamentlink}
        style={{
          cursor: "pointer",
          margin: "0rem 0rem 2rem 0rem",
          padding: "0rem",
          color: "#6c47ff",
          fontFamily: "Montserrat",
          fontWeight: "500",
          fontSize: "14px",
          letterSpacing: "1px",
        }}
      >
        {" "}
        {todo2.tournamentlink}{" "}
      </a>
      </div>

      {/* <button className="invitebutton">Join Now</button> */}
    </div>
  );
};

export default Invite;
