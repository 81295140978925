import {
  useDynamicContext,
  DynamicContextProvider,
  DynamicWidget,
} from "@dynamic-labs/sdk-react";
import { useState, useEffect } from "react";
import Nav from "./Nav";
import "./Auth.css";

function Auth() {
  const [loading, setLoading] = useState(false);

  //   const handleLogin = async (e) => {
  //     e.preventDefault();

  //     try {
  //       setLoading(true);
  //       const { error } = await supabase.auth.signInWithOtp({ email });
  //       if (error) throw error;
  //       alert("Check your email for the login link!");
  //     } catch (error) {
  //       alert(error.error_description || error.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };


  const { user, handleLogOut, setShowAuthFlow, showAuthFlow, walletConnector, authToken } = useDynamicContext();

  useEffect(() => {
    if (user && walletConnector) {
      const provider = walletConnector.getWeb3Provider();
    }
  }, [user, walletConnector]);

  if (user && !showAuthFlow) {
    return (
      <div>
        <Nav />
      </div>
    );
  }

  return (
    <div>
      <div className="mainh1">
        <h1 style={{fontWeight: '900'}}>REX TOURNAMENT INVITE PORTAL</h1>
      </div>
      <div className="mainp">
        <DynamicWidget
          buttonClassName="connectButton"
          innerButtonComponent="Connect Wallet"
        />{" "}
      </div>
    </div>
  );
}

export default Auth;

