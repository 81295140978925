import React from "react";
import "./App.css";
import Auth from "./Auth";
import { DynamicContextProvider } from "@dynamic-labs/sdk-react";

function App() {
  return (
    <DynamicContextProvider
      settings={{
        environmentId: "c1cc4177-3619-4256-9404-d45817ac68db",
        initialAuthenticationMode: "connect-and-sign",
        multiWallet: true,
        onAuthSuccess: ({ authToken, user }) => {
          /// Your logic goes here....
          // API calls, window.location.assign('/success'), etc.
        },
      }}
    >
      <Auth />
    </DynamicContextProvider>
  );
}

export default App;
