import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import CreateIcon from "@mui/icons-material/Create";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import LogoutIcon from "@mui/icons-material/Logout";
import { DynamicWidget } from "@dynamic-labs/sdk-react";
import TourneyInvites from "./assets/tourneyinviteslogo.svg";
import { useNavigate } from 'react-router-dom';
import Auth from "./Auth";
import Home from "./Home";
import MyInvites from "./MyInvites";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export default function Nav() {
  
  return (
    <Router>
    <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/myinvites' element={<MyInvites/>} />
    </Routes>
    </Router>
  );
}
