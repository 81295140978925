import React from "react";
import "./Home.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import CreateIcon from "@mui/icons-material/Create";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import TourneyInvites from "./assets/tourneyinviteslogo.svg";
import { DynamicWidget, useDynamicContext } from "@dynamic-labs/sdk-react";
import { useState, useEffect } from "react";
import { supabase } from "./supabaseClient";
import CreatedInvitesList from "./CreatedInvitesList";
import { useNavigate } from "react-router-dom";
import { styled, createTheme } from "@mui/material/styles";
import { Avatar, TextField, Chip, Autocomplete } from "@mui/material";
import { top } from "@popperjs/core";

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
    // Default transform is "translate(14px, 20px) scale(1)""
    // This lines up the label with the initial cursor position in the input
    // after changing its padding-left.
    transform: "translate(34px, 20px) scale(1);",
  },
  "&.Mui-focused .MuiInputLabel-outlined": {
    backgroundColor: "purple",
  },
  "& .MuiAutocomplete-inputRoot": {
    color: "purple",
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-of-type': {
      // Default left padding is 6px
      paddingLeft: 10,
      color: "white",
      fontSize: "14px",
      fontFamily: "Montserrat",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px solid transparent",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "0px solid transparent",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "0px solid transparent",
    },
  },
});

const drawerWidth = 240;

function Home({ authuser }) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [currentwallet, setCurrentWallet] = useState(null);
  const [tournamentname, setTournamentName] = useState(null);
  const [tournamentlink, setTournamentLink] = useState(null);

  const [invitednfts, setInvitedNFTs] = useState(topCollections[0]);

  const {
    user,
    handleLogOut,
    setShowAuthFlow,
    showAuthFlow,
    walletConnector,
    authToken,
  } = useDynamicContext();

  useEffect(() => {
    if (user.walletPublicKey != null) {
      setCurrentWallet(user.walletPublicKey);
    }
    getProfile();
  }, [user.walletPublicKey]);

  const getProfile = async () => {
    try {
      setLoading(true);

      let { data, error, status } = await supabase
        .from("invites")
        .select("id")
        .order("id", { ascending: false });

      if (error && status !== 406) {
        throw error;
      }

      if (data) {
        setTournamentName(data.tournamentname);
        setTournamentLink(data.tournamentlink);
        setInvitedNFTs(data.invitednfts);
      }
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const createInvite = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      console.log("These are the invited NFTs: " + invitednfts);

      const updates = {
        id: user.id,
        currentwallet,
        tournamentname,
        tournamentlink,
        invitednfts,
        updated_at: new Date(),
      };

      let { error } = await supabase.from("invites").insert(updates);

      if (error) {
        throw error;
      }
    } catch (error) {
      alert(error.message);
    } finally {
      window.location.reload(false);
      setLoading(false);
      setTournamentLink(null);
      setTournamentName(null);
      setInvitedNFTs(null);
    }
  };

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            height: "5rem",
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
            backgroundColor: "#000000",
          }}
        >
          <Toolbar
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              paddingTop: "1.5rem",
            }}
          >
            <DynamicWidget />
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: "#15181F",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "1rem 0rem 0rem 0rem",
            }}
          >
            <img src={TourneyInvites} alt="tourney logo" width="200px" />
          </div>
          <List style={{ padding: "0rem 0rem 0rem 0.5rem" }}>
            <ListItem
              key={"Create Invite"}
              onClick={() => navigate("/")}
              style={{ padding: "1rem 0rem 0rem 0rem" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <CreateIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"CREATE INVITE"}
                  primaryTypographyProps={{
                    fontFamily: "Montserrat",
                    fontWeight: "800",
                    fontSize: "14px",
                    letterSpacing: "1px",
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              key={"My Invites"}
              onClick={() => navigate("/myinvites")}
              style={{ padding: "0.5rem 0rem 0rem 0rem" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <MailIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"MY INVITES"}
                  primaryTypographyProps={{
                    fontFamily: "Montserrat",
                    fontWeight: "800",
                    fontSize: "14px",
                    letterSpacing: "1px",
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              key={"Help"}
              onClick={() => {
                window.location.href = `https://www.r3x.tech/contact`;
              }}
              style={{ padding: "0.5rem 0rem 0rem 0rem" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <QuestionMarkIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"HELP"}
                  primaryTypographyProps={{
                    fontFamily: "Montserrat",
                    fontWeight: "800",
                    fontSize: "14px",
                    letterSpacing: "1px",
                  }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "background.default",
            p: 3,
            backgroundColor: "#000000",
          }}
        >
          <Toolbar />
          <div className="App2">
            <div className="dashboardh3">
              <div>
                <div>
                  <p
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "64px",
                      fontFamily: "Montserrat",
                      fontStyle: "italic",
                      fontWeight: "900",
                      margin: "0.5rem 0rem 0rem 0rem",
                      padding: "0rem 0rem 0rem 0rem",
                    }}
                  >
                    CREATE INVITE
                  </p>
                </div>
                <div>
                  {loading ? (
                    "Saving ..."
                  ) : (
                    <form onSubmit={createInvite} className="form-widget">
                      <div>
                        <label
                          htmlFor="tournamentname"
                          style={{
                            justifyContent: "start",
                            display: "flex",
                            color: "white",
                            fontFamily: "Montserrat",
                            fontWeight: "800",
                            fontSize: "14px",
                            letterSpacing: "1px",
                          }}
                        >
                          Tournament Name
                        </label>
                        <input
                          id="tournamentname"
                          className="input"
                          type="text"
                          value={tournamentname || ""}
                          onChange={(e) => setTournamentName(e.target.value)}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="tournamentlink"
                          style={{
                            justifyContent: "start",
                            display: "flex",
                            color: "white",
                            fontFamily: "Montserrat",
                            fontWeight: "800",
                            fontSize: "14px",
                            letterSpacing: "1px",
                          }}
                        >
                          Tournament Link
                        </label>
                        <input
                          id="tournamentlink"
                          className="input"
                          type="url"
                          value={tournamentlink || ""}
                          onChange={(e) => setTournamentLink(e.target.value)}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="invitednfts"
                          style={{
                            justifyContent: "start",
                            display: "flex",
                            color: "white",
                            fontFamily: "Montserrat",
                            fontWeight: "800",
                            fontSize: "14px",
                            letterSpacing: "1px",
                          }}
                        >
                          NFT Collections to invite
                        </label>
                        <StyledAutocomplete
                          multiple
                          id="combo-box-demo"
                          options={topCollections}
                          getOptionLabel={(option) => option.name}
                          filterSelectedOptions
                          style={{
                            width: 600,
                            backgroundColor: "#15181F",
                            marginTop: "10px",
                          }}
                          value={invitednfts || []}
                          onChange={(event, newValue) => {
                            console.log(newValue);
                            setInvitedNFTs(
                              newValue.map((option) => option.value || option)
                            );
                          }}
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                // label="Combo box"
                                fullWidth
                              />
                            );
                          }}
                          renderOption={(props, option) => {
                            return (
                              <span {...props}>
                                <p
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "14px",
                                    color: "black",
                                  }}
                                >
                                  {option.name}
                                </p>
                              </span>
                            );
                          }}
                          renderTags={(options, getTagProps) =>
                            options.map((option, index) => (
                              <Chip
                                style={{
                                  backgroundColor: "#665eff",
                                  fontFamily: "Montserrat",
                                  fontSize: "14px",
                                }}
                                label={`${option.name}`}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                        />
                      </div>
                      <div>
                        <button
                          className="button primary block"
                          disabled={loading}
                          style={{
                            justifyContent: "center",
                            display: "flex",
                            color: "white",
                            fontFamily: "Montserrat",
                            fontWeight: "800",
                            fontSize: "14px",
                            letterSpacing: "1px",
                          }}
                        >
                          Create
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
            <div className="dashboardh3">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontStyle: "italic",
                  fontWeight: "900",
                  fontSize: "36px",
                  letterSpacing: "1px",
                  margin: "5rem 0rem 0rem 0rem",
                  padding: "0rem 0rem 0rem 0rem",
                }}
              >
                ACTIVE INVITES
              </p>
            </div>
            <CreatedInvitesList currentwallet={currentwallet} />
          </div>
        </Box>
      </Box>
    </div>
  );
}

// Top Solana and Ethereum NFT Collections
const topCollections = [
  {
    name: "Solana Monkey Business",
    chain: "Solana",
    website: "https://solanamonkey.business/",
    collectionaddress: "SMBH3wF6baUj6JWtzYvqcKuj2XCKWDqQxzspY12xPND",
  },
  {
    name: "y00ts",
    chain: "Solana",
    website: "https://www.y00ts.com/",
    collectionaddress: "4mKSoDDqApmF1DqXvVTSL6tu2zixrSSNjqMxUnwvVzy2",
  },
  {
    name: "DeGods",
    chain: "Solana",
    website: "https://degods.com/",
    collectionaddress: "6XxjKYFbcndh2gDcsUrmZgVEsoDxXMnfsaGY6fpTJzNr",
  },
  {
    name: "ABC",
    chain: "Solana",
    website: "https://twitter.com/ABC123Community",
    collectionaddress: "ES2iF5ctjqvtopPn4n6K7c9fdHjYg41rYXL2XzJK37jF",
  },
  {
    name: "Okay Bears",
    chain: "Solana",
    website: "https://www.okaybears.com/",
    collectionaddress: "3saAedkM9o5g1u5DCqsuMZuC4GRqPB4TuMkvSsSVvGQ3",
  },
  {
    name: "Claynosaurz",
    chain: "Solana",
    website: "https://claynosaurz.com/",
    collectionaddress: "6mszaj17KSfVqADrQj3o4W3zoLMTykgmV37W4QadCczK",
  },
  {
    name: "Degenerate Ape Academy",
    chain: "Solana",
    website: "https://www.degenape.academy/",
    collectionaddress: "DSwfRF1jhhu6HpSuzaig1G19kzP73PfLZBPLofkw6fLD",
  },

  {
    name: "OKB POG COLLECTORS EDITION",
    chain: "Solana",
    website: "https://www.pogdigital.com/",
    collectionaddress: "EFd3WV11WGPzw6FVGimGMnmkRFvqpvLTaABPJpZpRmun",
  },

  {
    name: "OKB POG PLAYERS EDITION",
    chain: "Solana",
    website: "https://www.pogdigital.com/",
    collectionaddress: "CVNzZnxBCixNtrePR6Y1CFH1quEeFFUuYbShCPM6dZAu",
  },

  {
    name: "Bored Ape Yacht Club",
    chain: "Ethereum",
    website: "https://boredapeyachtclub.com/#/home",
    collectionaddress: "0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D",
  },

  {
    name: "Mutant Ape Yacht Club",
    chain: "Ethereum",
    website: "https://boredapeyachtclub.com/#/home",
    collectionaddress: "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
  },

  {
    name: "CryptoPunks",
    chain: "Ethereum",
    website: "https://www.larvalabs.com/cryptopunks",
    collectionaddress: "0xb47e3cd837dDF8e4c57F05d70Ab865de6e193BBB",
  },

  {
    name: "Meebits",
    chain: "Ethereum",
    website: "https://larvalabs.com/project/meebits",
    collectionaddress: "0x7Bd29408f11D2bFC23c34f18275bBf23bB716Bc7",
  },

  {
    name: "Doodles",
    chain: "Ethereum",
    website: "https://doodles.app/",
    contractaddress: "0x8a90CAb2b38dba80c64b7734e58Ee1dB38B8992e",
  },

  {
    name: "Azuki",
    chain: "Ethereum",
    website: "https://www.azuki.com/",
    collectionaddress: "0xED5AF388653567Af2F388E6224dC7C4b3241C544",
  },

  {
    name: "BEANZ Official",
    chain: "Ethereum",
    website: "https://www.azuki.com/beanz",
    collectionaddress: "0x306b1ea3ecdf94aB739F1910bbda052Ed4A9f949",
  },

  {
    name: "Moonbirds",
    chain: "Ethereum",
    website: "https://www.moonbirds.xyz/",
    collectionaddress: "0x23581767a106ae21c074b2276D25e5C3e136a68b",
  },

  {
    name: "CLONE X - X TAKASHI MURAKAMI",
    chain: "Ethereum",
    website: "https://rtfkt.com/",
    collectionaddress: "0x49cF6f5d44E70224e2E23fDcdd2C053F30aDA28B",
  },

  {
    name: "Cool Cats NFT",
    chain: "Ethereum",
    website: "https://www.coolcatsnft.com/",
    collectionaddress: "0x1A92f7381B9F03921564a437210bB9396471050C",
  },

  {
    name: "World of Women",
    chain: "Ethereum",
    website: "https://www.worldofwomen.art/",
    collectionaddress: "0xe785E82358879F061BC3dcAC6f0444462D4b5330",
  },

  {
    name: "CryptoKitties",
    chain: "Ethereum",
    website: "https://www.cryptokitties.co/",
    collectionaddress: "0x06012c8cf97BEaD5deAe237070F9587f8E7A266d",
  },

  {
    name: "Pudgy Penguins",
    chain: "Ethereum",
    website: "https://www.pudgypenguins.com/",
    collectionaddress: "0xBd3531dA5CF5857e7CfAA92426877b022e612cf8",
  },

  {
    name: "VeeFriends",
    chain: "Ethereum",
    website: "https://veefriends.com/",
    collectionaddress: "0xa3AEe8BcE55BEeA1951EF834b99f3Ac60d1ABeeB",
  },
];

export default Home;
