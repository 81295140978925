import { useState, useEffect } from "react";
import Invite from "./Invite";
import { supabase } from "./supabaseClient";

export default function CreatedInvitesList({ currentwallet}) {
  const [todos, setTodos] = useState([]);

  useEffect(() => {
    fetchTodos().catch(console.error);
  }, []);

  const fetchTodos = async () => {
    let { data: todos, error } = await supabase
      .from("users")
      .select()
      .order("id", { ascending: false });
    if (error) console.log("error", error);
    else setTodos(todos);
  };

  return (
    <div style={{margin: '0rem 0rem 5rem 0rem', display: 'grid', justifyContent: 'center', alignItems: 'center', width: '100w'}}>
      {todos.length ? (
        todos.map((todo) => <Invite key={todo.id}todo={todo} />)
      ) : (
        <span style={{justifyContent: "center", fontFamily: "Montserrat", fontWeight: "400", padding: "2rem 0rem 34rem 0rem",
 }}>
          YOU HAVE NOT CREATED ANY INVITES YET!
        </span>
      )}
    </div>
  );
}
