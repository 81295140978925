import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import CreateIcon from "@mui/icons-material/Create";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import LogoutIcon from "@mui/icons-material/Logout";
import { DynamicWidget } from "@dynamic-labs/sdk-react";
import TourneyInvites from "./assets/tourneyinviteslogo.svg";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDynamicContext } from "@dynamic-labs/sdk-react";
import MyInvitesList from "./MyInvitesList";


const drawerWidth = 240;

export default function MyInvites() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [currentwallet, setCurrentWallet] = useState(null);


  const {
    user,
    handleLogOut,
    setShowAuthFlow,
    showAuthFlow,
    walletConnector,
    authToken,
  } = useDynamicContext();

  useEffect(() => {
    if (user.walletPublicKey != null) {
      setCurrentWallet(user.walletPublicKey);
    }
  }, [user.walletPublicKey]);

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            height: "5rem",
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
            backgroundColor: "#000000",
          }}
        >
          <Toolbar
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              paddingTop: "1.5rem",
            }}
          >
            <DynamicWidget />
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: "#15181F",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "1rem 0rem 0rem 0rem",
            }}
          >
            <img src={TourneyInvites} alt="tourney logo" width="200px" />
          </div>
          <List style={{ padding: "0rem 0rem 0rem 0.5rem" }}>
            <ListItem
              key={"Create Invite"}
              onClick={() => navigate("/")}
              style={{ padding: "1rem 0rem 0rem 0rem" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <CreateIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"CREATE INVITE"}
                  primaryTypographyProps={{
                    fontFamily: "Montserrat",
                    fontWeight: "800",
                    fontSize: "14px",
                    letterSpacing: "1px",
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              key={"My Invites"}
              onClick={() => navigate("/myinvites")}
              style={{ padding: "0.5rem 0rem 0rem 0rem" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <MailIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"MY INVITES"}
                  primaryTypographyProps={{
                    fontFamily: "Montserrat",
                    fontWeight: "800",
                    fontSize: "14px",
                    letterSpacing: "1px",
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              key={"Help"}
              onClick={() => {
                window.location.href = `https://www.r3x.tech/contact`;
              }}
              style={{ padding: "0.5rem 0rem 0rem 0rem" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <QuestionMarkIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"HELP"}
                  primaryTypographyProps={{
                    fontFamily: "Montserrat",
                    fontWeight: "800",
                    fontSize: "14px",
                    letterSpacing: "1px",
                  }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "background.default",
            p: 3,
            backgroundColor: "#000000",
          }}
        >
          <Toolbar />
          <div className="App4">
            <div className="dashboardh5">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontStyle: "italic",
                  fontWeight: "900",
                  fontSize: "36px",
                  letterSpacing: "1px",
                  margin: "2rem 0rem 0rem 0rem",
                  padding: "0rem 0rem 0rem 0rem",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                MY INVITES
              </p>
            </div>
            <MyInvitesList currentwallet={ currentwallet } />
          </div>
        </Box>
      </Box>
    </div>
  );
}
