import { useState, useEffect } from "react";
import MyInvite from "./MyInvite";
import { supabase } from "./supabaseClient";

export default function MyInvites({ currentwallet }) {
  const [todos2, setTodos2] = useState([]);
  const [currentwallet2, setCurrentWallet2] = useState([]);


  useEffect(() => {
    console.log(currentwallet);
    setCurrentWallet2(currentwallet);
    
    const fetchTodos = async () => {
      let { data: todos2, error } = await supabase
        .from('users')
        .select()
        .eq('currentwallet', `${currentwallet2}`);
      if (error) console.log("error", error);
      else setTodos2(todos2);
    };
    console.log(todos2);


    fetchTodos().catch(console.error);
  }, [currentwallet2, todos2, currentwallet]);
  

  return (
    <div
      style={{
        margin: "0rem 0rem 0rem 0rem",
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
        width: "100w",
      }}
    >
      {todos2.length ? (
        todos2.map((todo2) => <MyInvite key={todo2.id+1000000} todo2={todo2} />)
      ) : (
        <span
          style={{
            justifyContent: "center",
            fontFamily: "Montserrat",
            fontWeight: "400",
            padding: "1rem 0rem 35rem 0rem",
          }}
        >
          YOU HAVE NOT CREATED ANY INVITES YET!
        </span>
      )}
    </div>
  );
}
